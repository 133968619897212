<template>
  <div class="row animated fadeIn">
    <div class="col-lg-12 d-flex flex-column">
      <button class="btn btn-info btn-md ml-auto" @click="openModal(true)" v-if="!isDisable">
        {{ $t('paymentSystems.add') }}
      </button>

<!--      <Table-pagination-v2-->
<!--        :on-submit="getWithQuery.bind(this)"-->
<!--        v-model="filters"-->
<!--        :show-filters="['search', 'sort', 'limit']"-->
<!--        :options="[-->
<!--          { value: 'identity', label: $t('paymentSystems.identity') },-->
<!--          { value: 'name', label: $t('paymentSystems.name') }-->
<!--        ]"-->
<!--      />-->
      <Table-pagination-v3
        v-if="showTablePaginationV3Component"
        small-width-location-selector
        small-height-for-custom-multiselect-and-select-components
        :on-submit="getWithQuery.bind(this)"
        v-model="filters"
        :show-filters="['search', 'sort', 'limit', 'location']"
        :options="[
          { value: 'identity', label: $t('paymentSystems.identity') },
          { value: 'name', label: $t('paymentSystems.name') }
        ]"
      />
    </div>
    <div class="col-lg-4">
      <div class="filters__multiple">
        <div class="input-group input-group-sm filters__wlan flex-wrap mr-1">
          <button
            v-if="isFiltersActive"
            class="btn-outline-secondary btn btn-sm  new-modern-style-btn-in-filters mt-0"
            @click.stop.prevent="resetAllFilters"
          >
            <i class="fa fa-close"></i>
            {{ $t('general.resetAllFilters') }}
          </button>
        </div>
      </div>
    </div>

    <div class="col-lg-12">
      <div class="card">
        <!--        <div class="loader loader-backdrop loader-backdrop-portalpaymentsystems" v-if="isPaymentSystemsLoading"></div>-->
        <div v-if="isPaymentSystemsLoading"><Loader-spinner /></div>
        <div class="card-header">
          <i class="glyphicons glyphicons-credit-card glyphicons--shrink mr-0" />
          {{ $t('paymentSystems.title') }}
          <span class="text-muted ml-3" v-if="paymentSystemsList.length">
            {{ this.paymentSystemsList.length }} {{ this.$t('portal.paginationOf') }}
            {{ this.totalResultPaymentSystems }}
          </span>
        </div>
        <div class="card-block card-block__table">
          <!-- <h5 class="mb-1"></h5> -->
          <!-- <div class="row">
          <div class="col-lg-12">-->
          <table class="table table-hover">
            <thead>
              <tr>
                <th></th>
                <th scope="col" class="text-nowrap align-middle">{{ $t('paymentSystems.name') }}</th>
                <th scope="col" class="text-nowrap align-middle">{{ $t('paymentSystems.type.type') }}</th>
                <th scope="col" class="text-nowrap align-middle">{{ $t('paymentSystems.head') }}</th>
                <th scope="col" class="text-nowrap align-middle">{{ $t('paymentSystems.merchant') }}</th>
                <th scope="col" class="text-nowrap align-middle">{{ $t('paymentSystems.image') }}</th>
                <th scope="col" class="text-nowrap align-middle">{{ $t('paymentSystems.identity') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(system, index) in paymentSystemsList" :key="system.id" @click="openModal(false, system)">
                <td class="align-middle">{{ index + 1 }}</td>
                <td class="align-middle">
                  <span v-if="system.name">
                    {{ system.name }}
                  </span>
                  <EmptyCellValue v-else />
                </td>
                <td class="align-middle">
                  <h5 v-if="system.type">
                    <span
                      class="badge font-weight-normal"
                      :class="{
                        'badge-success': system.type === 'ext_url',
                        'badge-info': system.type === 'guide',
                        'badge-dark': system.type === 'ext'
                      }"
                    >
                      {{ $t(`paymentSystems.type.${system.type}`) }}
                    </span>
                  </h5>
                  <EmptyCellValue v-else />
                </td>
                <td class="align-middle">
                  <!--                  <span v-if="system.head">{{ JSON.parse(system.head)[currentLocale.toLowerCase()] }}</span>-->
                  <span v-if="system.head">{{ extractHeadFromSystemForShowInTable(system) }}</span>
                  <EmptyCellValue v-else />
                </td>
                <td class="align-middle">
                  <span v-if="system.merchant">
                    {{ system.merchant }}
                  </span>

                  <EmptyCellValue v-else />
                </td>
                <td class="align-middle">
                  <span
                    v-if="system.image"
                    v-tooltip.top-center="{
                      content: `<img src='${system.image}' class='img-responsive img-thumbnail'>`
                    }"
                    class="btn btn-outline-info btn-sm"
                  >
                    <i class="fa fa-eye mr-q" />
                    {{ $t('paymentSystems.preview') }}
                  </span>
                  <EmptyCellValue v-else />
                </td>
                <td class="align-middle">
                  <span v-if="system.identity">
                    {{ system.identity }}
                  </span>
                  <EmptyCellValue v-else />
                </td>
              </tr>
            </tbody>
          </table>
          <!-- </div>
          </div>-->
          <div
            v-if="
              this.canLoadMorePaymentSystems &&
              this.paymentSystemsList.length &&
              this.totalResultPaymentSystems - this.paymentSystemsList.length
            "
            class="card-block text-center p-0"
          >
            <button class="btn btn-block" @click="loadMore">
              {{ this.$t('portal.paginationLoad') }}
              <span v-if="this.filters.limit < this.totalResultPaymentSystems - this.paymentSystemsList.length">
                {{ this.filters.limit }}
              </span>
              <span v-else>{{ this.totalResultPaymentSystems - this.paymentSystemsList.length }}</span>
              {{ this.$t('portal.paginationPaymentSystemsOutOf') }}
              {{ this.totalResultPaymentSystems - this.paymentSystemsList.length }}
            </button>
          </div>
          <!--            </div>-->
          <!--          </div>-->
        </div>
      </div>
    </div>

    <modal
      :backdrop="false"
      :title="isNew ? this.$t('paymentSystems.new') : this.$t('paymentSystems.edit')"
      @click.native="handleModalClick"
      class="modal-info"
      closeBtn
      effect="fade/zoom"
      v-model="isModalOpen"
    >
      <h4 slot="modal-title" class="modal-title">{{ isNew ? $t('paymentSystems.new') : $t('paymentSystems.edit') }}</h4>

      <div class="form-group">
        <label for="name">{{ $t('paymentSystems.name') }}</label>
        <input
          id="name"
          name="name"
          type="text"
          class="form-control input"
          :readonly="isDisable"
          v-model.trim="paymentSystem.name"
        />
      </div>

      <div class="form-group">
        <label for="type">{{ $t('paymentSystems.type.type') }}</label>
        <multiselect
          :allow-empty="false"
          :close-on-select="true"
          :custom-label="customLabelSelect"
          :multiple="false"
          :options="paymentSystemTypeOptions"
          :placeholder="$t('paymentSystems.type.type')"
          :show-labels="false"
          id="type"
          name="type"
          v-model="paymentSystem.type"
        ></multiselect>
      </div>

      <div v-if="paymentSystem.type === 'ext'" class="form-group">
        <label for="merchant" class="required-mark">
          {{ $t('paymentSystems.merchant') }}
          <info :content="$t('paymentSystems.merchantInfo')" />
        </label>
        <input
          id="merchant"
          name="merchant"
          aria-describedby="merchantCaption"
          type="text"
          class="form-control input"
          :readonly="isDisable"
          v-model.trim="paymentSystem.merchant"
          v-validate="'required'"
        />
        <span class="text-muted small" id="merchantCaption">{{ $t('paymentSystems.merchantCaption') }}</span>
        <span
          v-tooltip.top-center="{ content: errors.first('merchant') }"
          v-show="errors.has('merchant')"
          class="help is-danger error_tooltip"
        >
          <i class="fa fa-warning text-error" />
        </span>
      </div>

      <div v-if="paymentSystem.type === 'ext'" class="form-group">
        <label for="profile" class="required-mark">{{ $t('paymentSystems.profile') }}</label>
        <multiselect
          :allow-empty="false"
          :close-on-select="true"
          :multiple="false"
          :options="portalProfilesList"
          :placeholder="$t('paymentSystems.profile')"
          :show-labels="false"
          @input="changeProfile"
          aria-describedby="profileCaption"
          id="profile"
          label="name"
          name="profile"
          trackBy="id"
          v-model="currentPaymentSystemProfile"
          v-validate="'required'"
        ></multiselect>
        <!-- <span class="text-muted small" id="profileCaption">{{ $t('paymentSystems.profileCaption') }}</span> -->
        <span
          v-tooltip.top-center="{ content: errors.first('profile') }"
          v-show="errors.has('profile')"
          class="help is-danger error_tooltip"
        >
          <i class="fa fa-warning text-error" />
        </span>
      </div>

      <div v-if="paymentSystem.type === 'ext'" class="form-group mb-q">
        <Switch-component
          v-model="paymentSystem.tran_unique"
          :disabled="isDisable"
          :label="$t('paymentSystems.tranUnique')"
          id="transaction-unique"
        />
      </div>

      <div class="form-group">
        <label for="identity" class="required-mark">{{ $t('paymentSystems.identity') }}</label>
        <input
          id="identity"
          name="identity"
          aria-describedby="identityCaption"
          type="text"
          class="form-control input"
          :readonly="isDisable"
          v-model.trim="paymentSystem.identity"
          v-validate="'required'"
        />
        <span class="text-muted small" id="identityCaption">{{ $t('paymentSystems.identityCaption') }}</span>
        <span
          v-tooltip.top-center="{ content: errors.first('identity') }"
          v-show="errors.has('identity')"
          class="help is-danger error_tooltip"
        >
          <i class="fa fa-warning text-error" />
        </span>
      </div>

      <div class="form-group">
        <p class="mb-q">{{ $t('paymentSystems.image') }}</p>

        <div class="d-flex align-items-start" v-if="!isDisable">
          <div>
            <label for="image" class="btn btn-primary mb-0">
              <i class="fa fa-file-image-o mr-h" />
              {{ $t('general.chooseFile') }}
              <input id="image" type="file" ref="image-upload" class="upload" @change="onFileChange" />
            </label>
            <p class="text-muted small mb-h">(jpg, png)</p>
          </div>

          <button v-if="paymentSystem.image" class="ml-h btn btn-danger" @click="() => (paymentSystem.image = '')">
            {{ $t('general.delete') }}
          </button>
        </div>

        <img
          v-if="paymentSystem.image"
          :src="paymentSystem.image"
          :alt="$t('paymentSystems.image')"
          class="img-preview"
        />
      </div>

      <div class="form-group">
        <div class="d-flex flex-column">
          <label for="name">{{ $t('paymentSystems.head') }}</label>

          <div class="btn-group float-left" data-toggle="button">
            <label class="btn btn-outline-primary" :class="{ active: header.currentLang === 'ru' }">
              <input type="radio" name="header-ru" @click="header.currentLang = 'ru'" />
              {{ $t('portalStats.locale.ru') }}
            </label>
            <label class="btn btn-outline-primary" :class="{ active: header.currentLang === 'en' }">
              <input type="radio" name="header-en" @click="header.currentLang = 'en'" />
              {{ $t('portalStats.locale.en') }}
            </label>
            <label class="btn btn-outline-primary" :class="{ active: header.currentLang === 'tg' }">
              <input type="radio" name="header-tg" @click="header.currentLang = 'tg'" />
              {{ $t('portalStats.locale.tg') }}
            </label>
          </div>
        </div>

        <input
          id="head"
          name="head"
          type="text"
          class="form-control input"
          :readonly="isDisable"
          v-model="header.text[header.currentLang]"
        />
      </div>

      <div class="form-group">
        <div class="d-flex flex-column">
          <label for="directions">{{ $t('paymentSystems.directions') }}</label>

          <div class="btn-group float-left" data-toggle="button">
            <label class="btn btn-outline-primary" :class="{ active: guide.currentLang === 'ru' }">
              <input type="radio" name="guide-ru" @click="guide.currentLang = 'ru'" />
              {{ $t('portalStats.locale.ru') }}
            </label>
            <label class="btn btn-outline-primary" :class="{ active: guide.currentLang === 'en' }">
              <input type="radio" name="guide-en" @click="guide.currentLang = 'en'" />
              {{ $t('portalStats.locale.en') }}
            </label>
            <label class="btn btn-outline-primary" :class="{ active: guide.currentLang === 'tg' }">
              <input type="radio" name="guide-tg" @click="guide.currentLang = 'tg'" />
              {{ $t('portalStats.locale.tg') }}
            </label>
          </div>
        </div>

        <textarea
          id="directions"
          name="directions"
          class="form-control form-group input"
          rows="5"
          :disabled="isDisable"
          v-model="guide.text[guide.currentLang]"
        />
      </div>

      <div v-if="paymentSystem.type === 'ext_url'" class="form-group">
        <label for="templateURL">{{ $t('paymentSystems.templateURL') }}</label>
        <input
          id="templateURL"
          name="templateURL"
          type="text"
          class="form-control input"
          :readonly="isDisable"
          v-model.trim="paymentSystem.template_url"
        />
      </div>

      <div v-if="paymentSystem.type === 'ext_url'" class="form-group">
        <label for="templateHash">{{ $t('paymentSystems.templateHash') }}</label>
        <input
          id="templateHash"
          name="templateHash"
          type="text"
          class="form-control input"
          :readonly="isDisable"
          v-model.trim="paymentSystem.template_hash"
        />
      </div>

      <div v-if="paymentSystem.type === 'ext_url'" class="form-group">
        <label for="hashKey">{{ $t('paymentSystems.hashKey') }}</label>
        <input
          id="hashKey"
          name="hashKey"
          type="text"
          class="form-control input"
          :readonly="isDisable"
          v-model.trim="paymentSystem.hash_key"
        />
      </div>

      <div slot="modal-footer" class="modal-footer justify-content-between">
        <button
          v-if="!isDisable"
          type="button"
          class="btn btn-outline-success mr-1"
          :disabled="errors.any() || muteEdit"
          @click="handleSave"
        >
          <span :class="{ invisible: muteEdit }">{{ isNew ? $t('general.create') : $t('general.save') }}</span>
          <span v-if="muteEdit" class="loader loader--mini" />
        </button>
        <div>
          <button
            v-if="!isNew && !isDisable"
            type="button"
            class="btn btn-outline-danger mr-2"
            @click="isDeleteOpen = true"
          >
            {{ $t('general.delete') }}
          </button>

          <button type="button" class="btn btn-outline-secondary" @click="cancelEditMode">
            {{ $t('general.close') }}
          </button>
        </div>
      </div>
    </modal>

    <delete-dialog
      :is-open="isDeleteOpen"
      :handle-close="handleCloseDeleteDialog.bind(this)"
      :handle-delete="deletePaymentSystem.bind(this)"
      :target-text="paymentSystem.name ? paymentSystem.name : paymentSystem.identity"
    >
      <template v-slot:additional>
        <WarningLine class="mt-1 mb-0">
          <i18n path="paymentSystems.warnUsage" tag="span" for="paymentSystems.warnUsageAccent">
            <span class="font-weight-bold text-danger">{{ $t('paymentSystems.warnUsageAccent') }}</span>
          </i18n>
        </WarningLine>
      </template>
    </delete-dialog>

    <close-dialog
      :is-open="isCloseModalOpen"
      :handle-yes="handleConfirmClose.bind(this)"
      :handle-no="closeConfirmModal.bind(this)"
    />
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import CloseDialog from '../../components/close-dialog.vue';
import DeleteDialog from '../../components/delete-dialog.vue';
import EmptyCellValue from '../../components/empty-cell-value.vue';
import helpers from '../../helpers';
import Modal from '../../components/Modal.vue';
import portalPaymentSystemsService from '../../services/portalPaymentSystemsService';
import portalProfilesService from '../../services/portalProfilesService';
import TablePaginationV2 from '../../components/table-pagination-v2.vue';
import TablePaginationV3 from '../../components/table-pagination-v3.vue';
import WarningLine from '../../components/warning-line.vue';
import SwitchComponent from '../../components/Universal/Switch-component.vue';
import Info from '../../components/Universal/info-icon.vue';

const watcher = new helpers.ChangesWatcher();

export default {
  name: 'PaymentSystems',
  components: {
    Multiselect,
    Modal,
    EmptyCellValue,
    WarningLine,
    DeleteDialog,
    CloseDialog,
    TablePaginationV2,
    SwitchComponent,
    Info,
    TablePaginationV3

  },
  data() {
    return {
      showTablePaginationV3Component: true,
      canLoadMorePaymentSystems: true,
      currentPaymentSystemProfile: false,
      isCloseModalOpen: false,
      isDeleteOpen: false,
      isModalOpen: false,
      isNew: true,
      offset: 0,
      paymentSystem: {},
      paymentSystemTypeOptions: ['guide', 'ext', 'ext_url'],
      totalResultPaymentSystems: false,
      newPaymentSystem: {
        id: '',
        name: '',
        identity: '',
        image: '',
        text: '',
        template_url: '',
        template_hash: '',
        hash_key: ''
      },
      header: {
        text: {
          ru: '',
          en: '',
          tg: ''
        },
        currentLang: 'ru'
      },
      guide: {
        text: {
          ru: '',
          en: '',
          tg: ''
        },
        currentLang: 'ru'
      },
      filters: {
        limit: 30,
        offset: 0,
        sort_by: 'identity',
        sort_order: 1 /* 1 for ascending, -1 for descending */,
        search: '',
        location: {
          data: {},
          with_childs: false,
        }
      }
    };
  },

  methods: {
    extractHeadFromSystemForShowInTable(system) {
      // console.log(system.head)
      try {
        if (Object.prototype.hasOwnProperty.call(system, 'head')) {
          // пытаемся распарсить как JSON
          const head = JSON.parse(system.head);
          const keysInHead = Object.keys(head);
          // если получилось распарсить как json
          if (keysInHead.includes(this.currentLocale.toLowerCase())) {
            // если там есть ключ, совпадающией с текущей локалью - возвращаем его значение
            return head[this.currentLocale.toLowerCase()];
          } if (keysInHead.length > 0) {
            // если есть хоть какой-нибудь ключ - возарщаем его значение
            return head[keysInHead[0]];
          }
          return '';
        }
        return '';
      } catch (e) {
        if (e instanceof SyntaxError) {
          // если происходит ошибка при попытке распрасить JSON - райзится SyntaxError
          console.warn("system.head is not JSON. And can't be parsed");
          // console.log(e);
          return system.head; // если не распарсился - тогда возвращаем просто строку
        }
        console.log(e);
        return '';
      }
    },
    // resetAllFilters() {
    //   this.filters = {
    //     limit: 30,
    //     offset: 0,
    //     sort_by: 'identity',
    //     sort_order: 1 /* 1 for ascending, -1 for descending */,
    //     search: '',
    //     location: {
    //       data: {},
    //       with_childs: false,
    //     }
    //   }
    //   this.redrawTablePaginationV3Component();
    //   this.getWithQuery();
    //
    // },
    resetAllFilters() {
      this.filters = {
        limit: 30,
        offset: 0,
        sort_by: 'identity',
        sort_order: 1 /* 1 for ascending, -1 for descending */,
        search: '',
        // location: {
        //   data: {},
        //   with_childs: false,
        // }
      }
      if (!this.isSelectedDefaultLocationForRequests) {
        this.filters.location = {};
        this.filters.location.data = {};
        this.filters.location.with_childs = false;
      } else {
        this.filters.location = {};
        this.filters.location.data = this.$store.getters.getInHeaderLocationSelectBlockData.selectedLocationObject;
        this.filters.location.with_childs = this.$store.getters.getDefaultWithChildsForRequests(this)
      }
      this.redrawTablePaginationV3Component();
      this.getWithQuery();

    },
    redrawTablePaginationV3Component() {
      this.showTablePaginationV3Component = false;
      this.$nextTick(() => {
        this.showTablePaginationV3Component = true;
      })
    },
    changeProfile(value) {
      this.paymentSystem.profile = value.id;
    },
    customLabelSelect(value) {
      switch (value) {
        case 'ext':
          return this.$t('paymentSystems.type.ext');
        case 'ext_url':
          return this.$t('paymentSystems.type.ext_url');
        case 'guide':
          return this.$t('paymentSystems.type.guide');
        default:
          return value;
      }
    },
    loadMore() {
      this.offset++;
      this.filters.offset = this.offset * this.filters.limit;
      // console.log(this.filters.offset);
      // portalPaymentSystemsService.getPaymentSystems(this, { query: true });
      portalPaymentSystemsService.getPaymentSystems(this, { query: true, useLocationFilter: true });
    },
    clearQuery() {
      this.offset = 0;
      this.filters.offset = 0;
      this.canLoadMorePaymentSystems = true;
      this.$store.commit('portalPaymentSystemsClean');
    },
    openModal(isNew, paymentSystem) {
      this.$validator.reset();
      this.isModalOpen = true;
      if (isNew) {
        this.isNew = true;
        this.paymentSystem = { ...this.newPaymentSystem };
      } else {
        this.isNew = false;
        this.paymentSystem = { ...paymentSystem };
      }
      try {
        this.header.text = JSON.parse(this.paymentSystem.head);
      } catch (e) {
        this.header.text = { [this.header.currentLang]: this.paymentSystem.head };
      }
      try {
        this.guide.text = JSON.parse(this.paymentSystem.text);
      } catch (e) {
        this.guide.text = { [this.guide.currentLang]: this.paymentSystem.text };
      }
      watcher.reset();
    },
    handleSave() {
      this.$validator.validate().then((valid) => {
        if (valid) {
          if (this.isNew) {
            this.createPaymentSystem(this.tariff);
          } else {
            this.updatePaymentSystem(this.tariff);
          }
        }
      });
    },
    cancelEditMode() {
      this.isModalOpen = false;
      this.isDeleteOpen = false;
      /* Prevents validation blinking during closing animation */
      this.$validator.reset();
    },
    closeConfirmModal() {
      this.isCloseModalOpen = false;
      helpers.handleSecondModalHiding();
    },
    handleConfirmClose() {
      this.closeConfirmModal();
      helpers.handleBothModalsHiding();
      this.cancelEditMode();
    },
    handleModalClick() {
      if (watcher.isChanged) {
        this.isCloseModalOpen = true;
      } else {
        this.cancelEditMode();
      }
    },
    updatePaymentSystem(tariff) {
      portalPaymentSystemsService.updatePaymentSystem(this, this.paymentSystem);
    },
    createPaymentSystem(tariff) {
      portalPaymentSystemsService.createPaymentSystem(this, this.paymentSystem);
    },
    deletePaymentSystem() {
      portalPaymentSystemsService.deletePaymentSystem(this, this.paymentSystem);
    },
    getWithQuery() {
      this.clearQuery();
      // portalPaymentSystemsService.getPaymentSystems(this, { query: true });
      portalPaymentSystemsService.getPaymentSystems(this, { query: true, useLocationFilter: true });
    },
    onFileChange() {
      const file = this.$refs['image-upload'].files[0];

      if (file === undefined) {
        return;
      }

      helpers
        .getBase64(file)
        .then((data) => (this.paymentSystem.image = data))
        .catch((err) => console.error(err));
    },
    handleCloseDeleteDialog() {
      this.isDeleteOpen = false;
    }
  },
  watch: {
    paymentSystem: {
      deep: true,
      handler() {
        watcher.tick();
      }
    },
    guide: {
      deep: true,
      handler() {
        const guideToSend = { ...this.guide.text };
        this.paymentSystem.text = JSON.stringify(guideToSend);
      }
    },
    header: {
      deep: true,
      handler() {
        const headerToSend = { ...this.header.text };
        this.paymentSystem.head = JSON.stringify(headerToSend);
      }
    }
  },
  computed: {
    isSelectedDefaultLocationForRequests() {
      if (this.$store.getters.getDefaultLocationForRequests(this)) {
        return true;
      } else {
        return false;
      }
    },
    userBaseLocation() {
      return this.$store.state.userData.base_location;
    },
    isFiltersActive() {
      return this.filters.limit !== 30 || this.filters.sort_by !== 'identity' ||
        this.filters.sort_order !== 1 || this.filters.search !== '' ||
        (typeof this.filters.location === 'object' && Object.prototype.hasOwnProperty.call(this.filters.location, 'data') &&
        typeof this.filters.location.data  === 'object' && Object.keys(this.filters.location.data).length !== 0 )
    },
    muteEdit() {
      return this.$store.state.mutePortalPaymentSystemEdit;
    },
    isDisable() {
      return this.$store.state.userData.role === 'operator';
    },
    paymentSystemsList() {
      return this.$store.state.portalPaymentSystemsList;
    },
    isPaymentSystemsLoading() {
      return this.$store.state.loadingPortalPaymentSystems;
    },
    portalProfilesList() {
      return this.$store.state.portalProfilesList;
    },
    currentLocale() {
      return localStorage.getItem('NMS_LOCALE');
    }
  },
  // created() {
  //   this.clearQuery();
  //   this.$store.commit('portalProfilesListClean');
  //   if (this.portalProfilesList.length < 1) portalProfilesService.getPortalProfiles(this);
  //   portalPaymentSystemsService.getPaymentSystems(this, { query: true });
  // }
  created() {
    let inHeaderLocationSelectedApplying = true;
    if (this.$store.state.inHeaderLocationSelectModeEnabled &&
      inHeaderLocationSelectedApplying) {
      let withChildsForFiltering = this.$store.getters.getInHeaderLocationSelectBlockData.withChilds;
      let locationForFiltering = this.$store.getters.getInHeaderLocationSelectBlockData.selectedLocationID;
      if (!locationForFiltering || locationForFiltering === '') {
        // locationForFiltering = this.$store.getters.getInHeaderLocationSelectBlockData.userBaseLocation
        locationForFiltering = 'All locations';
      }
      if (locationForFiltering === 'All locations') {
        this.filters.location.data = {};
        this.filters.location.with_childs = true;
      } else {
        this.filters.location.data = this.$store.getters.getInHeaderLocationSelectBlockData.selectedLocationObject
        this.filters.location.with_childs = withChildsForFiltering
      }
    }
    this.clearQuery();
    this.$store.commit('portalProfilesListClean');
    // if (this.portalProfilesList.length < 1) portalProfilesService.getPortalProfiles(this);
    if (this.portalProfilesList.length < 1) portalProfilesService.getPortalProfilesFromPaymentSystems(this);
    // portalPaymentSystemsService.getPaymentSystems(this, { query: true });
    portalPaymentSystemsService.getPaymentSystems(this, { query: true, useLocationFilter: true });

  }
};
</script>

<style lang="scss" scoped>
.upload {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.img-preview {
  width: auto;
  height: auto;
  max-width: 468px;
  max-height: 150px;

  margin: 0;
  padding: 0;
}
</style>

<style lang="scss">
.loader-backdrop-portalpaymentsystems:after {
  position: absolute;
  top: 30px;
}
</style>
